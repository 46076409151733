import React from 'react';
import { Component } from 'react';
import axios from 'axios';
import {Button, Row, Col} from 'reactstrap'
import DealsList from '../../components/DealsList'


export default class Widget extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      deals: [],
      loadingDeals: true
     };
  }

  componentDidMount() {
    this.updateDeals();
    
  }

  sendSDKMessage(type, args) {
    console.log(`sending message: ${type} with arguments: ${args}`);
    try {
      if (/android/i.test(window.navigator.userAgent)) {
        window.ButtonSdk && window.ButtonSdk.onMessageReceived(JSON.stringify({
          "type": type,
          "arguments": args
        }));
      }
      else {
        window.webkit.messageHandlers.ButtonSDK.postMessage({
          "type": type,
          "arguments": args
        });
      }
    } catch (e) {
      console.log(e.toString());
      console.log(`Couldn't start widget`);
    }
  }

  updateDeals = () => {
    this.fetchAllDeals()
    .then(res => {
      this.setState({...this.state, deals: res.data.objects, loadingDeals: false, timerTime: 240})
      this.sendSDKMessage("Button.boost.ready", { "success": true });
      
    })
    .catch(error => {
      this.setState({...this.state, deals: []})
    })
    
  }

  fetchAllDeals = () => {
    const retailer = new URLSearchParams(window.location.search).get('retailer');
    if (retailer) {
      return axios.get(`/deals?retailer=${retailer}&include_expired=false`);
    }
    return axios.get(`/deals`);
  }

  render() {
    
    if (this.state.deals.length < 1) {
      return<p>Loading...</p>
    }
    return (
      <div>
        <h1 className="widget-h1">Today's Deals</h1>
        <DealsList deals={this.state.deals} widget={true} sendSDKMessage={this.sendSDKMessage} ></DealsList>
      </div>
    );
  }
}