import logo from './logo.svg';
import './App.css';
import Home from './screens/home/Home';
import Widget from './screens/widget/Widget'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

function App() {
  return (
    <Router>

          <Switch>
            <Route path="/widget">
              <Widget></Widget>
            </Route>
            <Route path="/">
              <div className="App">
                <header className="App-header">
                  <Home></Home>
                </header>
              </div>
            </Route>
          </Switch>

    </Router>
  );
}

export default App;
