import React from 'react';
import { Component } from 'react';
import axios from 'axios';
import {Button, Row, Col} from 'reactstrap'
import DealsList from '../../components/DealsList'


export default class Home extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      deals: [],
      timerTime: 240,
      loadingDeals: true
     };
  }

  componentDidMount() {
    this.updateDeals();
    this.interval = setInterval(() => this.updateTimer(), 1000);

  }

  updateTimer() {
    const time = this.state.timerTime - 1;
    console.log(`updating timer time: ${time}`)
    if (time > 0) {
      this.setState({ ...this.state, timerTime: time });
    }
    else {
      this.setState({ ...this.state, timerTime: 240, loadingDeals: true });
      this.updateDeals();
    }
  }

  componentWilUnmount() {
    window.removeEventListener("focus", this.onFocus)
  }

  onFocus = () => {
    let currentDate = new Date();
    let secondsSinceUpdate = (currentDate - this.state.lastRefreshDate) / 1000;
    
    if (this.state.lastRefreshDate && secondsSinceUpdate < 300) {
      console.log('Skipping onFocus refresh due to fresh data'); 
    }
    else {
      console.log('Triggering update onFocus');
      this.updateUsers();
    }
  }

  updateDeals = () => {
    this.fetchAllDeals()
    .then(res => {
      this.setState({...this.state, deals: res.data.objects, loadingDeals: false, timerTime: 240})
    })
    .catch(error => {
      this.setState({...this.state, deals: []})
    })
  }

  fetchAllDeals = () => {
    const retailer = new URLSearchParams(window.location.search).get('retailer');
    if (retailer) {
      return axios.get(`/deals?retailer=${retailer}`);
    }
    return axios.get(`/deals`);
  }

  render() {
    
    if (this.state.deals.length < 1) {
      return<p>Loading...</p>
    }
    return (
      <div>
        <h1>Live Deals</h1>
        <p className="powered-by-button">Powered by Button</p>

        <div className="countdown-bar">
          <Row>
            <Col xs="3">
              <img src="/img/spinner.gif" className="countdown-timer-img" width="80" height="80"></img>
            </Col>
            <Col xs="9">
              {(() => {
                if (!this.state.loadingDeals) {
                  return <div>
                    <p className="countdown-bar-text">Next deals update in....</p>
                    <p className="countdown-bar-text countdown-bar-time">{this.state.timerTime}s</p>
                  </div>;
                }
                else {
                  return <p className="countdown-bar-text">Loading deals...</p>;
                }
              return 'Start Call'
            })()}
              
            </Col>
          </Row>
        </div>

        <DealsList deals={this.state.deals} ></DealsList>
      </div>
    );
  }
}