import React from 'react';
import { Component } from 'react';
import { Button, Row, Col } from 'reactstrap';

export default class DealsList extends Component {

  constructor(props) {
    super(props);
    this.state = { }
  }

  displayPrice(deal) {
    var priceString = `${deal.product.price}`;
    var intPrice = parseInt(deal.product.price);
    if (!intPrice || intPrice < 1) {
      return "See Price";
    }
    if (!priceString.includes('$')) {
      priceString = `$${priceString}`;
    }
    return priceString
  }

  shouldShowOriginalPrice(deal) {
    try {
      const currentPrice = parseInt(this.displayPrice(deal).replace(/[^0-9.-]+/g,""));
      const originalPrice = parseInt(this.originalPrice(deal).replace(/[^0-9.-]+/g,""));
      return (currentPrice && originalPrice && originalPrice > currentPrice);
    }
    catch (e) {
      return false;
    }
    
  }

  originalPrice(deal) {
    var slashPriceString = `$${deal.product.msrp}`;
    return slashPriceString;
  }

  openDeal(deal) {
    if (this.props.widget) {
      this.props.sendSDKMessage('Button.url.open', { url: deal.url });
    }
  }

  faviconUrlFor(deal) {
    try {
      return `https://www.google.com/s2/favicons?sz=64&domain_url=${ new URL(deal.url).hostname }`;
    } catch (e) {
      return '';
    }
  }

  render() {
    return (
      
      <div className={ this.props.widget ? 'deals-list-widget' : 'deals-list'}>
        {
          this.props.deals.filter(deal => deal.product && deal.product.title && deal.product.price && deal.product.image_url).map(deal => (
            <a href={this.props.widget ? '#' : deal.url} onClick={() => this.openDeal(deal)} target={this.props.widget ? '' : "_blank"}>
            <div className={`deal-card ${deal.expired ? 'expired' : ''}`}>
              <div className="user-card-header">
                <div>
                  <img src={this.faviconUrlFor(deal)} className="store-favicon" />
                  <img src={deal.product.image_url} className="image" />
                </div>
              </div>
              
              <div className="deal-card-details">
                <div >
                  <p>{(deal.product.title || '').slice(0,46)}</p>
                  {/* <p>{deal.product.name}</p> */}
                  <p className="deal-card-price"><span className="deal-card-price-original">{this.shouldShowOriginalPrice(deal) ? this.originalPrice(deal) : ''} </span>{ this.displayPrice(deal)}</p>
                  </div>
                  
                </div>
            </div>
            </a>
          ))
        }
          
        
      </div>
    )
  }
}

